<template>
  <div
    class="tendency-wrap"
    v-loading.fullscreen="loading"
    element-loading-text="加载中"
  >
    <div class="tendency-breadcrumb">
      <span
        class="pointer"
        @click="toBrandTendency"
      >商业洞察</span> > <span
        class="pointer"
        @click="toBrandTopDetail"
      >品牌榜单</span> > <span class="active"> {{title}}</span>
    </div>
    <div class="tendency-content">
      <div class="tendency-left">
        <div class="brand-top-select w-input-sty">
          <el-select
            class="brand-top-select-input"
            v-model="year"
            popper-class="w-block-select-down"
            @change="topSelectHandle"
            placeholder="请选择"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <ul class="brand-top-list">
          <li
            v-for="item in topList"
            :key='item.topId'
            :class="[item.topId===top? 'active': '']"
            @click="getTopDetail(item)"
          >{{item.title}}</li>
        </ul>
      </div>
      <div class="tendency-right">
        <div class="top-title">{{title}}</div>
        <div
          class="top-content"
          v-html="introduction"
        >
        </div>
        <div class="top-tip">
          <p>榜单意义：鼓励品牌推陈出新，监测品牌发展轨迹，预测零售行业演变新趋势；</p>
          <p>测评方法：结合赢商大数据独家品牌评价体系、品牌进驻城市等级、购物中心等级、品牌创新实力、品牌门店增速、拓展城市等级、拓展规模、网络口碑热度、社交媒体活跃度、品牌承租能力等多个维度进行综合测评。最终排名根据市场热度指数、发展潜力指数、营运能力指数进行综合测评得出。排名越靠前，代表场所端认可度越高。</p>
        </div>
        <div class="top-table-list top-brand-table">
          <el-table
            :data="tableData"
            class="overviewEmptyTable"
            @row-click='toBrandDetail'
            style="width: 100%"
          >
            <template slot="empty">
              <div
                class="no-sign-brand"
                style="margin-top:127px"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>暂无相关数据</p>
              </div>
            </template>
            <el-table-column
              prop="index"
              width='50'
              label=""
            >
              <template slot-scope="scope">
                <div class="top-index" v-if="scope.row.ranking">
                  <span
                    v-if="scope.row.ranking ===1"
                    class="top-index-1"
                  ></span>
                  <span
                    v-else-if="scope.row.ranking ===2"
                    class="top-index-2"
                  ></span>
                  <span
                    v-else-if="scope.row.ranking ===3"
                    class="top-index-3"
                  ></span>
                  <p v-else>{{scope.row.ranking ? scope.row.ranking : '' }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌"
              width="230"
            >
              <template slot-scope="scope">
                <div class="top-brand">
                  <div class="top-brand-logo">
                    <img
                      v-if="!scope.row.brandLogo"
                      src="@/assets/images/public/notPic.jpg"
                      alt=""
                    >
                    <img
                      v-else
                      :src="scope.row.brandLogo"
                    >
                  </div>
                  <div class="top-brand-name"> {{scope.row.brandName}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="industryType"
              label="业态"
            >
            </el-table-column>
            <el-table-column
              prop="need"
              label="需求面积"
            >
              <template slot-scope="scope">
                <span>{{scope.row.areaMin}}-{{scope.row.areaMax}} 平米</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="拓展状态"
            >
              <template slot-scope="scope">
                <span>{{scope.row.isTuozhan===1?'拓展选址中': '暂不拓展'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="已进驻购物中心"
              width='160'
            >
              <template slot-scope="scope">
                <span>已进驻{{scope.row.newShopNum}}家购物中心</span>
              </template>
            </el-table-column>
            <el-table-column width="40">
              <template slot-scope="scope">
                <span v-if="false">{{scope.row.name}}</span>
                <i class="el-icon-arrow-right top-arrow"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      yearList: [],
      year: '',
      topList: [
      ],
      top: '',
      topDetail: {},
      tableData: [
      ],
      loading: false,
      introduction: '',
      title: ''
    }
  },
  components: {
  },
  created () {
    // const startYear = 2015
    // const endYear = new Date().getFullYear()
    // this.year = Number(this.$route.query.year || endYear)
    // this.top = this.$route.query.topId
    // for (let i = startYear; i <= endYear; i++) {
    //   this.yearList.unshift(i)
    // }
    this.getYearListFunc()
  },
  methods: {
    getYearListFunc () {
      this.axios.post(api.getYearList)
        .then((res) => {
          this.yearList = res.data.data.map((item) => {
            const obj = {
              value: Number(item.value),
              label: item.label
            }
            return obj
          })
          this.year = Number(this.$route.query.year || this.yearList[0].value)
          this.top = this.$route.query.topId
          this.getBrandTopListFuc()
        })
    },
    toBrandDetail (detail) {
      if (!detail.gbid) {
        this.$message({
          type: 'warning',
          message: '该品牌不在查看范围',
          duration: 3000
        })
        return
      }
      const page = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: detail.brandId,
          source: 1,
          gbid: detail.gbid,
          questionState: 0
        }
      })
      window.open('/' + page.href, '_blank')
    },
    //
    topSelectHandle () {
      this.top = ''
      this.loading = true
      this.getBrandTopListFuc()
    },
    getBrandTopListFuc () {
      this.axios.post(api.getNewsBrandTopList, {
        yearName: this.year
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.loading = false
            if (res.data.data.length === 0) {
              this.$message({
                type: 'warning',
                message: '当前年份暂无品牌榜单,请重新选择',
                duration: 3000
              })
              return
            }
            this.topList = res.data.data.reverse()
            if (this.top) {
              this.topList.forEach(item => {
                if (Number(this.top) === Number(item.topId)) {
                  this.getTopDetail(item)
                }
              })
            } else {
              this.getTopDetail(this.topList[0])
            }
          }
        })
    },
    getTopDetail (data) {
      this.top = data.topId
      this.title = data.title
      this.introduction = data.introduction
      this.axios.post(api.getNewsBrandListDetail, {
        topId: data.topId
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data
          }
          // this.$router.push({
          //   path: '/brandTendencyTop',
          //   query: {
          //     topId: this.top,
          //     year: this.year
          //   }
          // })
        })
    },
    toBrandTendency () {
      this.$router.push({
        path: '/brandTendency'
      })
    },
    preHandle () {
      this.$router.go(-1)
    },
    toBrandTopDetail () {
      const page = this.$router.resolve({
        path: '/brandTendencyTop'
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.mb-20
  margin-bottom 20px
.tendency-wrap
  margin 0 auto
  width 1280px
  padding-bottom 30px
.tendency-content
  display flex
  background-color #272930
  min-height 780px
  .tendency-left
    width 346px
    background-color #2C2E35
  .tendency-right
    flex 1
    padding 30px 40px 38px 38px
.tendency-breadcrumb
  color #9B9B9B
  font-size 14px
  margin-bottom 15px
  margin-top 15px
  .active
    color #ffffff
.brand-top-select
  padding 30px 38px 32px 28px
.brand-top-list
  li
    font-size 16px
    color #999
    line-height 25px
    border-left 3px solid transparent
    padding-left 38px
    padding-top 12px
    padding-bottom 12px
    cursor pointer
    &.active
      border-left 3px solid #FFA134
      background-color #414349
      color #ffffff
    &:hover
      background-color #414349
      color #ffffff
.top-title
  font-size 24px
  color #ffffff
  font-weight 500
  margin-bottom 30px
.top-content
  font-size 16px
  color rgba(255, 255, 255, 0.8)
  line-height 26px
  margin-bottom 25px
.top-brand
  display flex
  align-items center
  padding 5px 0 10px 0
.top-brand-logo
  background-color #ffffff
  height 55px
  width 75px
  margin-right 8px
  img
    width 100%
    height 100%
.top-brand-name
  flex 1
  text-align left
.top-arrow
  font-size 12px
  transform scale(0.8)
  color #C0C0C0
.top-table-list
  text-align center
.top-index-1
  background url('~@/assets/images/public/top-index1.png') no-repeat
  background-size 100%
.top-index-2
  background url('~@/assets/images/public/top-index2.png') no-repeat
  background-size 100%
.top-index-3
  background url('~@/assets/images/public/top-index3.png') no-repeat
  background-size 100%
.top-index
  span
    display inline-block
    width 28px
    height 32px
.no-sign-brand
  text-align center
  margin-top 100px
  color #fff
.top-tip
  margin 20px 0
  background-color #44464b
  color #fff
  font-size 14px
  line-height 24px
  padding 15px
  border-radius 3px
</style>
